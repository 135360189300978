.form {
  background: $form-blokk-bg;
  .form-content {
    max-width: 1000px;
    margin: 0 auto;
    h2 {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
}
::-webkit-input-placeholder {
  color: #676f78;
}
:-moz-placeholder {
  color: #676f78;
}
::-moz-placeholder {
  color: #676f78;
}
:-ms-input-placeholder {
  color: #676f78;
}
.gform_wrapper {
  background: $form-blokk-bg !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 10px 10px 10px !important;
  .validation_error {
    display: none !important;
  }
  .gfield_error {
    margin-top: 0 !important;
  }
  .gfield_label {
    display: none !important;
  }
}
.ginput_container {
  input, select {
    color: #676f78 !important;
    width: 100% !important;
    margin-bottom: 10px;
    height: 40px;
    padding: 0 10px !important;
    font-size: 14px !important;
  }
  textarea {
    width: 100% !important;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    height: 150px !important;
  }
}
.gform_wrapper ul.gfield_checkbox li input[type=checkbox] {
  width: 36px !important;
  height: 36px;
}
.gfield_checkbox {
  input {
    float: right !important;
    height: 30px !important;
  }
  label {
    padding-top: 10px !important;
    font-weight: normal !important;
    float: right !important;
    margin-right: 10px !important;
    color: #fff;
    font-family: $arial !important;
    font-weight: 300 !important;
    width: 80% !important;
    a {
      color: $gold;
      &:hover {
        color: $gold;
      }
    }
  }
}
.gform_page {
  display: inline-block !important;
}
.gfield_html {
  color: white !important;
}
.gform_footer {
  background: $form-blokk-bg !important;
  padding: 0 10px 10px 10px;
  input[type="submit"] {
    position: relative;
    width: 100%;
    text-transform: uppercase;
    height: 40px;
    background-color: #c29f0d;
    color: #fff;
    font-size: 14px;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    @media (min-width: $screen-sm-min) {
      top: -70px;
    }
  }
  input[type="submit"] {
    &:hover {
      background-color: #c2a532;
    }
  }
}
.gravity-header {
  color: #fff;
  h2 {
    font-size: 30px;
    font-weight: bold;
    font-family: $arial;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 0;
    color: #fff !important;
  }
  .title {
    p {
      font-style: italic;
    }
  }
  p {
    font-size: 12px;
    text-align: justify;
    color: #fff;
    margin-bottom: 10px !important;
  }
}
.gform_ajax_spinner {
  display: none;
}