.main {
  /*  width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    @media (min-width: $screen-xs-min) {
      float: none;
    }
    */
  .home-content {
    max-width: 1100px;
    margin: 0 auto;
    text-align: center;
    h1 {
      margin-top: 72px;
      margin-bottom: 20px;
      font-family: $arial;
      font-weight: bold;
      font-size: 30px;
      text-transform: uppercase;
    }
    .the_content {
      p {
        font-size: 18px;
      }
    }
  }
  .first-block-content, .second-block-content, .third-block-content {
    max-width: 1000px;
    margin: 0 auto;
    padding-left: 100px;
  }
  .first-block {
    .elonyok, .kik-igenyelhetik {
      margin-bottom: 35px;
      h2 {
        @include font-settings(30px, $blokk-color);
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 90px;
        margin-bottom: 30px;
        margin-left: -30px;
      }
      ul {
        li {
          margin-bottom: 10px;
          @include font-settings(18px, $blokk-color);
          span {
            &:before {
              margin-right: 15px;
              font-weight: bolder;
              font-size: 16px;
              color: $gold-arrow;
            }
          }
        }
      }
    }
  }

  .second-block {
    background: $grey-blokk-bg;
    .second-block-content {
      padding-left: 130px;
      padding-right: 30px;
      padding-bottom: 45px;
      h2 {
        @include font-settings(30px, $blokk-color);
        text-transform: uppercase;
        font-weight: bold;
        margin-left: -15px;
        margin-top: 45px;
        margin-bottom: 30px;
        margin-left: -45px;
      }
      .left, .right {
        @include font-settings(18px, $blokk-color);
        margin-bottom: 10px;
        a {
          @include font-settings(18px, $blokk-color);
          text-decoration: underline;
        }
        span {
          &:before {
            margin-right: 15px;
            font-weight: bolder;
            font-size: 16px;
            color: $gold-arrow;
          }
        }
      }
      .left {
        padding-left: 15px;
        text-indent: -15px;
      }
      .right {
        @media (max-width: $screen-xs-max) {
          padding-left: 15px;
          text-indent: -15px;
        }
      }
    }
  }
  .third-block {
    .third-block-content {
      padding-bottom: 35px;
      h2 {
        @include font-settings(30px, $blokk-color);
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 50px;
        margin-bottom: 30px;
        margin-left: -30px;
      }
      div {
        @include font-settings(18px, $blokk-color);
        li {
          margin-bottom: 10px;
          padding-left: 30px;
          text-indent: -15px;
          a {
            color: $blokk-color;
            text-decoration: underline;
          }
        }
        span {
          &:before {
            margin-right: 15px;
            font-weight: bolder;
            font-size: 16px;
            color: $gold-arrow;
          }
        }
      }
    }
  }
}

.thanks-content {
  padding-top: 40px;
  padding-bottom: 60px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  h1 {
    font-size: 30px;
  }
}