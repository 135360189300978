.banner {
  .header-top {
    background: $header-top-bg;
    .navbar-brand {
      position: relative;
      width: 100%;
      text-align: center;
      margin: auto;
      height: 181px;
      padding: 20px 15px 15px;
      & > img {
        margin: 0 auto;
      }
    }
  }
  .header-bottom {
    background: $header-bottom-bg;
    height: 84px;
    .navbar-collapse {
      text-align: center;
      .navbar-nav {
        display: inline-block;
        float: none;
        @media (max-width: $screen-sm-max) {
          margin-top: 0;
          margin-bottom: 0;
        }
        & > li {
          @media (max-width: $screen-sm-max) {
            display: inline-block;
          }
          & > a {
            @include font-settings(15px, $header-color);
            text-transform: uppercase;
            padding-top: 32px;
            padding-bottom: 32px;
            @media (min-width: $screen-md-min) {
              font-size: 17px;
            }
            @media (min-width: $screen-lg-min) {
              padding-left: 25px;
              padding-right: 25px;
              font-size: 20px;
            }
            &:hover, &:focus {
              @include font-settings(15px, $header-color);
              @media (min-width: $screen-md-min) {
                font-size: 17px;
              }
              @media (min-width: $screen-lg-min) {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
.menu-sep {
  position: absolute;
  top: 18px;
  right: 0px;
  font-size: 31px;
  border-right: 2px solid $menu-sep;
  height: 50px;
}
li {
  &:last-child {
    .menu-sep {
      display: none;
    }
  }
}