// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;
$white:                 #fff;
$light-grey:            #f1f0f0;
$grey:                  #e1e1e1;
$brand-primary:         #27ae60;
$dark-blue:             #10263b;
$darker-blue:           #0c1c2c;
$gold:                  #c09e0e;

// Font family
$arial:                 Arial, 'Helvetica Neue', Helvetica, sans-serif;

// Header
$header-top-bg:         $dark-blue;
$header-bottom-bg:      $darker-blue;
$header-color:          $white;
$menu-sep:              $gold;

// Blokkok
$gold-arrow:            $gold;
$blokk-color:           $darker-blue;
$grey-blokk-bg:         $light-grey;
$form-blokk-bg:         $darker-blue;

// Footer
$footer-bg:             $darker-blue;
$footer-border:         $white;
$footer-text-color:     $white;