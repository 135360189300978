.content-info {
  background: $footer-bg;
  border-top: 1px solid $footer-border;
  padding-top: 40px;
  &>div {
    padding-bottom: 40px;
  }
  .logo {
    text-align: right;
    img {
      padding-right: 10px;
    }
  }
  .text {
    p {
      color: $footer-text-color;
      font-family: $arial;
      font-size: 18px;
    }
    .title {
      text-transform: uppercase;
      font-size: 20px;
    }
    .address {
      margin-bottom: 2px;
    }
  }
  #map {
    height: 495px;
  }
}